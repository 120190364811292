export const environment = {
  production: true,
  feUrl: 'https://social.madsense.io',
  serviceUrl: 'https://social-api.madsense.io/api',
  metaConfigId: '1220257238941913',
  metaInstagramConfigId: '792833459422160',
  metaAppId: '382794467576892',
  client_id: '781k0u4pah1tr5', // Used for LinkedIn
  redirect_uri:
    'https://social-api.madsense.io/api/social-media-connections/linkedin', // Replace with your actual redirect URI, Used for LinkedIn
  scope: 'w_member_social%2copenid%2cemail%2cprofile', // Define the scope of the access you are requesting, Used for LinkedIn
  sentryDsn: 'https://e2c9fdbe13f7335f833a9996f386f035@o4507844150427648.ingest.us.sentry.io/4507866426638336'
};
